import React from "react"

import { Link } from "gatsby";
import Page from "../components/Page";

import * as style from "./subscription.module.scss";

export default ({ location }) => {
  return (
    <Page location={location}>
        <div className={style.root}>
            <h3 className={style.header}>Subscription</h3>
        </div>
    </Page>
  );
}
